@media (max-width: 1000px) {
    .home{
        background-color: white;
        
    }
    .body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    height: 80vh;
    padding: 0 15px 15px 15px;
}

.body .col-12{
    display: flex;
    flex-direction: column;
}
.selection{
    border-radius: 10px;
    background-color: antiquewhite;
    padding: 10px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    padding-right: 10px;
    width: 100%;
    border: 2px solid salmon;
}
.icon{
    border-radius: 50%;
    margin-right: 3px;
    
}
.col-7{
    display: flex;
    justify-content: start;
}
.col-7>span{
    font-size: 20px;
    font-weight: 600;
    color: #0066b2;
}
.col-2{
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 5px;
}
h2{
    font-size: 20px;
    font-weight: 700;
    text-align: center;
}
p{
    text-align: center;
}
.modal-image{
    display: flex;
    justify-content: center;
    border-radius: 50%;
    margin-bottom: 20px;
}
.countdown{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    background-color: antiquewhite;
    margin: 0 auto;
    padding: 10px;
}
.time>span{
    font-size: 20px;
    background-color: white;
    padding: 5px 5px 5px 5px;
    margin:0 10px 0 10px;
}
.cover{
    font-size: 16px;
}

}
.body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    height: 80vh;
    padding: 0 15px 15px 15px;
}

.col-10 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 80h;
}

.col-10 h1 {
    margin: 0;
}

.col-10 span{
    margin-top: 10px;
    font-size: 16px;
    margin-top: 5px;
    text-align: left;
}
.body .col-12{
    display: flex;
    flex-direction: column;
}
.selection{
    border-radius: 10px;
    background-color: antiquewhite;
    padding: 10px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    padding-right: 10px;
    width: 100%;
    border: 2px solid red;
}
.icon{
    border-radius: 50%;
    
}
.col-7{
    display: flex;
    justify-content: start;
}
.col-7>span{
    font-size: 20px;
    font-weight: 600;
    color:#FF3800;
}
.col-2{
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 5px;
}
h2{
    font-size: 20px;
    font-weight: 700;
    text-align: center;
}
p{
    text-align: center;
}
.modal-image{
    display: flex;
    justify-content: center;
    border-radius: 50%;
    margin-bottom: 20px;
}
.countdown{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    background-color: antiquewhite;
    margin: 0 auto;
    padding: 10px;
}
.time>span{
    font-size: 20px;
    background-color: white;
    padding: 5px 5px 5px 5px;
    margin:0 10px 0 10px;
}
.cover{
    font-size: 16px;
}