h2{
    font-size: 20px;
    font-weight: 700;
    text-align: center;
}
p{
    text-align: center;
}
.modal-image{
    display: flex;
    justify-content: center;
    border-radius: 50%;
    margin-bottom: 20px;
}
.countdown{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    background-color: antiquewhite;
    margin: 0 auto;
    padding: 10px;
}
.time>span{
    font-size: 20px;
    background-color: white;
    padding: 5px 5px 5px 5px;
    margin:0 10px 0 10px;
}
.cover{
    font-size: 16px;
}